import _ from "lodash"

export const module1 = {
    namespaced: true,
    state: () => ({
        options: {
            calcType: '',
            case: {
                need_save: false
            },
            caseName: '',
            layerName: '',
            caseId: '',
            singleResult: true,
            singleResultCOS: true,
            resultIterationsCount: [
                {
                    name: '1', id: '1', isActive: true
                },
                {
                    name: '1000', id: '1000', isActive: false
                },
                {
                    name: '2000', id: '2000', isActive: false
                },
                {
                    name: '5000', id: '5000', isActive: false
                }
            ],
            distributionTypes: ['uniform', 'triangular', 'Meyerson', 'Normal'],
            //distributionTypes: ['uniform', 'triangular', 'Log_Normal_Percentile', 'Meyerson', 'Normal'],
            openPropSettings: false,
            propDataLoading: false,
            getResultTimerId: ''
        },
        properties: {
            grv: {
                title: 'property.grv.title',
                variant: 'range',
                range: {
                    grv: {
                        name: 'GRV',
                        imageUrl: '/img/props/grv.svg',
                        title: 'property.grv.title',
                        abbreviation: 'property.grv.abbreviation',
                        measurement: 'common.measurement.metric.mlnm3',
                        settings: {
                            min: 10,
                            max: 5000,
                            step: 1
                        },
                        value: 100,
                        fixed: true,
                        distribution: 'static',
                        deviation: 20,
                        activeTab: 'mean',
                        multiple_values: {},
                    }
                },
                area_thickness: {
                    area: {
                        name: 'Area',
                        imageUrl: '/img/props/area.svg',
                        title: 'property.grv.areaThickness.area.title',
                        abbreviation: 'property.grv.areaThickness.area.abbreviation',
                        measurement: 'common.measurement.metric.km2',
                        settings: {
                            min: 0.1,
                            max: 500,
                            step: 0.1
                        },
                        value: 10,
                        distribution: 'static',
                        fixed: true,
                        deviation: 20,
                        activeTab: 'mean',
                        multiple_values: {},
                    },
                    average_thickness: {
                        name: 'Thickness',
                        imageUrl: '/img/props/thickness.svg',
                        title: 'property.grv.areaThickness.average_thickness.title',
                        abbreviation: 'property.grv.areaThickness.average_thickness.abbreviation',
                        measurement: 'common.measurement.metric.m',
                        settings: {
                            min: 1,
                            max: 500,
                            step: 0.1
                        },
                        value: 10,
                        distribution: 'static',
                        fixed: true,
                        deviation: 20,
                        activeTab: 'mean',
                        multiple_values: {},
                    },
                    geometric_factor: {
                        name: 'Geometric factor',
                        imageUrl: '/img/props/gm_f.svg',
                        title: 'property.grv.areaThickness.geometric_factor.title',
                        abbreviation: 'property.grv.areaThickness.geometric_factor.abbreviation',
                        measurement: 'common.measurement.metric.d_ed',
                        settings: {
                            min: 0.5,
                            max: 1,
                            step: 0.01
                        },
                        value: 1,
                        distribution: 'static',
                        fixed: true,
                        deviation: 20,
                        activeTab: 'mean',
                        multiple_values: {},
                    }
                },
                manual: {
                    value: '',
                    filename: '',
                    rows: [],
                    charts: [],
                    thickness: {
                        name: 'thickness',
                        imageUrl: '/img/props/thickness-large.svg',
                        title: 'property.grv.manual.thickness.title',
                        measurement: 'common.measurement.metric.m',
                        settings: {
                            min: 1,
                            max: 500,
                            step: 0.1
                        },
                        value: 1,
                        distribution: 'static',
                        fixed: true,
                        deviation: 20,
                        activeTab: 'mean',
                        multiple_values: {},
                    },
                    contact: {
                        name: 'contact',
                        imageUrl: '/img/props/contact-large.svg',
                        title: 'property.grv.manual.contact.title',
                        measurement: 'common.measurement.metric.depthm',
                        settings: {
                            min: 10,
                            max: 5000,
                            step: 1
                        },
                        value: 3000,
                        distribution: 'static',
                        fixed: true,
                        deviation: 20,
                        activeTab: 'mean',
                        multiple_values: {},
                    }
                }
            },
            ntg: {
                name: 'NTG',
                imageUrl: '/img/props/ntg.svg',
                title: 'property.ntg.title',
                abbreviation: 'property.ntg.abbreviation',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.01
                },
                fixed: true,
                value: 0.64,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},
            },
            phi: {
                name: 'Phi',
                imageUrl: '/img/props/phi.svg',
                title: 'property.phi.title',
                abbreviation: 'property.phi.abbreviation',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 0.4,
                    step: 0.001
                },
                fixed: true,
                value: 0.25,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            so: {
                name: 'So',
                imageUrl: '/img/props/so.svg',
                title: 'property.so.title',
                abbreviation: 'property.so.abbreviation',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.001
                },
                fixed: true,
                value: 0.5,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},
            },
            sg: {
                name: 'Sg',
                imageUrl: '/img/props/so.svg',
                title: 'property.sg.title',
                abbreviation: 'property.sg.abbreviation',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.001
                },
                fixed: true,
                value: 0.5,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            bo: {
                name: 'Bo',
                for: 'oil',
                imageUrl: '/img/props/bo.svg',
                title: 'property.bo.title',
                abbreviation: 'property.bo.abbreviation',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.001
                },
                fixed: true,
                value: 0.9,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            density: {
                name: 'Density',
                for: 'oil',
                imageUrl: '/img/props/density.svg',
                title: 'property.density.title',
                abbreviation: 'property.density.abbreviation',
                measurement: 'common.measurement.metric.gsm3',
                settings: {
                    min: 0.5,
                    max: 1.5,
                    step: 0.001
                },
                fixed: true,
                value: 0.85,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            gor: {
                name: 'Gas Oil Ratio',
                for: 'oil',
                imageUrl: '/img/props/gor.svg',
                title: 'property.gor.title',
                abbreviation: 'property.gor.abbreviation',
                measurement: 'common.measurement.metric.m3m3',
                settings: {
                    min: 0,
                    max: 1000,
                    step: 1
                },
                fixed: true,
                value: 100,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            rf_oil: {
                name: 'Recovery Factor',
                for: 'oil',
                imageUrl: '/img/props/kin.svg',
                title: 'property.rf_oil.title',
                abbreviation: 'property.rf_oil.abbreviation',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.01
                },
                fixed: true,
                value: 0.4,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            bg: {
                name: '1/Bg',
                for: 'gas',
                imageUrl: '/img/props/bg.svg',
                title: 'property.bg.title',
                abbreviation: 'property.bg.abbreviation',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 1,
                    max: 1000,
                    step: 1
                },
                fixed: true,
                value: 150,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            cy: {
                name: 'Condensate yield',
                for: 'gas',
                imageUrl: '/img/props/kgf.svg',
                title: 'property.cy.title',
                abbreviation: 'property.cy.abbreviation',
                measurement: 'common.measurement.metric.gm3',
                settings: {
                    min: 1,
                    max: 2000,
                    step: 1
                },
                fixed: true,
                value: 200,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            grf: {
                name: 'GRF',
                for: 'gas',
                imageUrl: '/img/props/kig.svg',
                title: 'property.grf.title',
                abbreviation: 'property.grf.abbreviation',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.01
                },
                fixed: true,
                value: 0.8,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            crf: {
                name: 'CRF',
                for: 'gas',
                imageUrl: '/img/props/kik.svg',
                title: 'property.crf.title',
                abbreviation: 'property.crf.abbreviation',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.01
                },
                fixed: true,
                value: 0.6,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            trap: {
                name: 'Trap',
                imageUrl: '/img/props/trap.svg',
                title: 'property.trap.title',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.01
                },
                fixed: true,
                value: 0.75,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            seal: {
                name: 'Seal',
                imageUrl: '/img/props/seal.svg',
                title: 'property.seal.title',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.01
                },
                fixed: true,
                value: 0.75,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            reservoir: {
                name: 'Reservoir',
                imageUrl: '/img/props/reservoir.svg',
                title: 'property.reservoir.title',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.01
                },
                fixed: true,
                value: 0.75,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            charge: {
                name: 'Charge',
                imageUrl: '/img/props/charge.svg',
                title: 'property.charge.title',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.01
                },
                fixed: true,
                value: 0.75,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            },
            timing_migration: {
                name: 'timing/migration',
                imageUrl: '/img/props/t_m.svg',
                title: 'property.timing_migration.title',
                measurement: 'common.measurement.metric.d_ed',
                settings: {
                    min: 0,
                    max: 1,
                    step: 0.01
                },
                fixed: true,
                value: 0.75,
                distribution: 'static',
                deviation: 20,
                activeTab: 'mean',
                multiple_values: {},

            }
        },
        propSettings: {
            title: '',
            code: '',
            distribution: '',
            multiple_values: [],
            required_parameters: [],
            crop: {
                min: '',
                max: ''
            },
            chart: {}
        },
        result: {
            data: {
                oil_ir: {values:{}},
                oil_rr: {values:{}},
                oil_gas_ir: {values:{}},
                oil_gas_rr: {values:{}},
                gas_ir: {values:{}},
                gas_rr: {values:{}},
                gas_oil_ir: {values:{}},
                gas_oil_rr: {values:{}},
                cos: {values:{}}
            }
        },
        lastResult: {
            inputData: null,
            outputData: null
        }
    }),
    getters: {
        allCaseData: (state) => {
            const removeKeys = ['imageUrl', 'measurement', 'timeout', 'timerId', 'title', 'abbreviation', 'settings', 'name', 'for', 'activeTab']
            return {
                ...Object.keys(state.properties).reduce((cur, key) => {
                    let result = typeof state.properties[key] === 'object' ? _.omit(state.properties[key], removeKeys) : state.properties[key]

                    return Object.assign(cur, {
                        [key]: result
                    })
                }, {}),
                grv: {
                    variant: state.properties.grv.variant,
                    range: {
                        ...Object.keys(state.properties.grv.range).reduce((cur, key) => {
                            let result = typeof state.properties.grv.range[key] === 'object' ? _.omit(state.properties.grv.range[key], removeKeys) : state.properties.grv.range[key]
        
                            return Object.assign(cur, {
                                [key]: result
                            })
                        }, {})
                    },
                    area_thickness: {
                        ...Object.keys(state.properties.grv.area_thickness).reduce((cur, key) => {
                            let result = typeof state.properties.grv.area_thickness[key] === 'object' ? _.omit(state.properties.grv.area_thickness[key], removeKeys) : state.properties.grv.area_thickness[key]
        
                            return Object.assign(cur, {
                                [key]: result
                            })
                        }, {})
                    },
                    manual: {
                        ...Object.keys(state.properties.grv.manual).reduce((cur, key) => {
                            let result = typeof state.properties.grv.manual[key] === 'object' ? _.omit(state.properties.grv.manual[key], removeKeys) : state.properties.grv.manual[key]
        
                            return Object.assign(cur, {
                                [key]: result
                            })
                        }, {})
                    }
                }
            }
        }
    },
    mutations: {
        saveLastDistribution(state, payload) {
            const propId = payload.id;
            if (payload.distribution !== 'static') {
                if (['grv', 'area', 'average_thickness', 'thickness', 'geometric_factor', 'contact'].includes(propId.split('.').slice(-1)[0])) {
                    _.set(state.properties, `${propId}.lastDistribution`, payload.distribution);
                } else {
                    state.properties[propId].lastDistribution = payload.distribution;
                }
            }
        },
        setCalcType(state, payload) {
            state.options.calcType = payload;
        },
        setCaseId(state, payload) {
            state.options.caseId = payload;
        },
        updateResultIterationsCount(state, payload) {
            const count = payload
            state.options.resultIterationsCount.forEach(element => {
                if (Number(element.id) === Number(count)) {
                    element.isActive = true
                } else {
                    element.isActive = false
                }
            })
        },
        checkSingleResult(state) {
            const COSKeys = ['trap', 'seal', 'reservoir', 'charge', 'timing_migration']
            
            for (const key in state.properties) {
                const prop = state.properties[key];
                if (key == 'grv') {
                    if (prop.variant == 'range') {
                        if (prop.range.grv.distribution != 'static') {
                            state.options.singleResult = false;
                            break;
                        } else {
                            state.options.singleResult = true;
                        }
                    } else if (prop.variant == 'area_thickness') {
                        let areaThicknessFixed = true;
                        for (let key in prop.area_thickness) {
                            if (prop.area_thickness[key].distribution != 'static') {
                                areaThicknessFixed = false;
                                break;
                            }
                        }
                        if (areaThicknessFixed == false) {
                            state.options.singleResult = false;
                            break;
                        }  else {
                            state.options.singleResult = true;
                        }
                    } else if (prop.variant == 'manual') {
                        let manualFixed = true;
                        for (let key in prop.manual) {
                            if (key == 'contact' || key == 'thickness') {
                                if (prop.manual[key].distribution != 'static') {
                                    manualFixed = false;
                                    break;
                                }
                            }
                        }

                        if (manualFixed == false) {
                            state.options.singleResult = false;
                            break;
                        } else {
                            state.options.singleResult = true;
                        }
                    }
                } else {
                    if (prop.distribution != 'static' || !prop.fixed) {
                        state.options.singleResult = false;
                        break;
                    } else {
                        state.options.singleResult = true;
                    }
                }
            }

            for (const key in state.properties) {
                const prop = state.properties[key];
                if (COSKeys.includes(key)) {
                    if (prop.distribution != 'static' || !prop.fixed) {
                        state.options.singleResultCOS = false;
                        break;
                    } else {
                        state.options.singleResultCOS = true;
                    }
                }
            }

            if (!state.options.singleResult) {
                const iterationsCountValue = state.options.resultIterationsCount.find(item => item.isActive)?.id
                iterationsCountValue < 1000 ? this.commit('module1/updateResultIterationsCount', 5000) : false;
            } else {
                this.commit('module1/updateResultIterationsCount', 1);
                clearTimeout(state.getResultTimerId);
                state.getResultTimerId = setTimeout(() => {
                    this.dispatch('module1/getResult');
                }, 700);
            }
        },
        switchTotalVolume(state, payload) {
            state.properties.grv.variant = payload.id;
            if (state.properties.grv.variant !== 'manual') {
                this.dispatch('module1/updatePropForm', 'grv');
            } else if (state.properties.grv.manual.rows.length) {
                this.dispatch('module1/updatePropForm', 'grv');
            }
            if (state.properties.grv.variant == 'area_thickness') {
                state.properties.ntg.value = 1;
            }
        }
    },
    actions: {
        async getDefaultProps({ state }, payload) {
            await $fetch('/api/get_default_props')
            .then(response => {
                for (let prop in response) {
                    if (prop == 'grv') {
                        state.properties[prop].variant = response[prop].variant;
                        for (let key in response[prop]) {
                            if (key == 'range' || key == 'area_thickness' || key == 'manual') {
                                for (let i in response[prop][key]) {
                                    for (let n in response[prop][key][i]) {
                                        state.properties[prop][key][i][n] = response[prop][key][i][n];
                                    }
                                }
                            }
                        }
                    } else {
                        for (let key in response[prop]) {
                            state.properties[prop][key] = response[prop][key]
                        }
                    }
                }

                this.dispatch('module1/getCase');
            })
            .catch(e => console.log(e))
        },
        async getCase({ state }, payload) {
            try {
                const response = await $fetch(`/api/get_case/${state.options.caseId}`)
                state.options.caseName = response.name;
                state.options.layerName = response.layer.name;
                state.options.case = response.case;
                state.options.calcType = response.case.type;

                for (let prop in response.form_props) {
                    if (prop == 'grv') {
                        state.properties[prop].variant = response.form_props[prop].variant;
                        for (let key in response.form_props[prop]) {
                            if (key == 'range' || key == 'area_thickness' || key == 'manual') {
                                for (let i in response.form_props[prop][key]) {
                                    for (let n in response.form_props[prop][key][i]) {
                                        state.properties[prop][key][i][n] = response.form_props[prop][key][i][n];
                                    }
                                }
                            }
                        }
                    } else {
                        for(let key in response.form_props[prop]) {
                            state.properties[prop][key] = response.form_props[prop][key]
                        }
                    }
                }

                this.commit('module1/checkSingleResult');
                this.dispatch('module1/getResult'); // TODO добавил, что бы результаты подгружались сразу

            } catch (e) {
                console.log(e)
            }
        },
        async updatePropertyState({ state }, payload) {
            const propId = payload.id;
            _.set(state.properties, propId, payload.data);
            try {
                return await this.dispatch('module1/updatePropForm', propId)
            } catch (e) {
                console.log(e)
            }
        },
        async updatePropForm({ state }, payload) {

            return new Promise((resolve, reject) => {

                const propId = payload;
                const propItem = _.get(state.properties, propId);

                if (propItem.timerId !== undefined) {
                    clearTimeout(propItem.timerId);
                }
                propItem.timeout = 0;

                propItem.timerId = setTimeout(function () {
                    updateProp(propId.split('.')[0]);
                }, 700);

                let updateProp = async (propId) => {

                    let requestData = {
                        prop: propId,
                        data: {}
                    };

                    function validatePropKeys(key) {
                        if (key != 'title' && key != 'measurement' && key !=='abbreviation' && key != 'imageUrl' && key != 'activeTab' && key != 'name' && key != 'settings') {
                            return true;
                        } else {
                            return false;
                        }
                    }

                    if (propId == 'grv') {
                        requestData.data.variant = state.properties[propId].variant
                        requestData.data[state.properties[propId].variant] = {}
                        for (let variant in state.properties[propId]) {
                            if (variant == state.properties[propId].variant) {
                                for (let prop in state.properties[propId][variant]) {
                                    if (variant == 'manual') {
                                        if (prop == "thickness" || prop == "contact") {
                                            requestData.data[state.properties[propId].variant][prop] = {}
                                            for (let key in state.properties[propId][variant][prop]) {
                                                if (validatePropKeys(key)) {
                                                    requestData.data[state.properties[propId].variant][prop][key] = state.properties[propId][variant][prop][key];
                                                }
                                            }
                                        } else if (prop == 'rows') {
                                            requestData.data[state.properties[propId].variant][prop] = state.properties[propId][variant][prop];
                                        }
                                    } else {
                                        requestData.data[state.properties[propId].variant][prop] = {}

                                        for (let key in state.properties[propId][variant][prop]) {
                                            if (validatePropKeys(key)) {
                                                requestData.data[state.properties[propId].variant][prop][key] = state.properties[propId][variant][prop][key];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (let key in state.properties[propId]) {
                            if (validatePropKeys(key)) {
                                requestData.data[key] = state.properties[propId][key]
                            }
                        }
                    }

                    const { data, pending, error, refresh } = await useFetch(`/api/update_prop_form/${state.options.caseId}/`, {
                        method: 'POST',
                        body: JSON.stringify(requestData)
                    })

                    if (error.value) {
                        const errorMessage = error.value.data

                        this.commit('common/setErrorData', {
                            statusCode: error.value.statusCode,
                            message: errorMessage.message,
                            showError: true
                        })

                        reject()
                        return
                    }

                    if (propId == 'grv') {
                        state.properties[propId].variant = data.value.prop_data.variant;

                        for (let key in data.value.prop_data) {
                            if (key == 'range' || key == 'area_thickness' || key == 'manual') {
                                for (let i in data.value.prop_data[key]) {
                                    for (let n in data.value.prop_data[key][i]) {
                                        state.properties[propId][key][i][n] = data.value.prop_data[key][i][n];
                                    }
                                }
                            }
                        }

                    } else {
                        for (let key in data.value.prop_data) {
                            state.properties[propId][key] = data.value.prop_data[key];
                        }
                    }

                    state.options.case = data.value.case;

                    this.commit('module1/checkSingleResult');

                    resolve(data.value);

                }
            })
        },
        async getResult({ state, getters }) {
            const iterationsCountValue = state.options.resultIterationsCount.find(item => item.isActive)?.id
            const { data, pending, error, refresh } = await useFetch(`/api/get_result/${state.options.caseId}/`, {
                method: 'POST',
                body: {
                    recalculate: state.options.recalculate,
                    result_quantity: iterationsCountValue,
                    data: getters.allCaseData
                }
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })
                this.commit('common/setLoadingStatus', false)

                useNuxtApp().$geologyUnitCaseError(errorMessage.message, new Error())
                
                return
            }

            useGtag().gtag('event', 'start_calculation', {
				'case_type': 'geology_units' 
			})

            if (typeof ym !== 'undefined') {
                ym(useRuntimeConfig().public.YM_ID,'reachGoal','start_calculation')
            }

            try {
                for (let key in data.value.values_charts) {
                    state.result.data[key] = data.value.values_charts[key]
                }
                state.options.case = data.value.case

                this.commit('common/setLoadingStatus', false)
            } catch (e) {
                useNuxtApp().$geologyUnitCaseError('Error getting result', e)
            }
            
        },
        async getTableInput({ state, rootState }) {
            try {
                const response = await $fetch(`/api/projects/${rootState.common.options.project.id}/layers/${rootState.common.options.layer.id}/cases/${state.options.caseId}/props`)
                state.lastResult.inputData = response
                return response
            } catch (e) {
                console.log(e)
            }
        },
        async getTableOutput({ state }) {
            try {
                const response = await $fetch(`/api/get_table/${state.options.caseId}/`)

                function sortResultColumns(object) {

                    let original = object
                    let sortable = []

                    original.columns.forEach(item => {
                        for (let key in original.result) {
                            if (item == key) {
                                original.result[key].id = key
                                sortable.push(original.result[key])
                            }
                        }
                    })

                    return sortable
                }

                response.main_table.result = sortResultColumns(response.main_table)
                response.risc_table.result = sortResultColumns(response.risc_table)

                state.lastResult.outputData = response
                return response

            } catch (e) {
                console.log(e)
            }
        },
        async getCSVParse({ state }, payload) {
            let form = new FormData()
            form.append('file_input', payload)

            try {
                const response = await $fetch(`/api/grv_csv_parse/${state.options.caseId}/`, {
                    method: 'POST',
                    body: form
                })
                state.properties.grv.manual.value = response.volume
                state.properties.grv.manual.rows = response.rows
                state.properties.grv.manual.charts = response.charts

                for (let key in response.thickness) {
                    state.properties.grv.manual.thickness[key] = response.thickness[key]
                }
                for (let key in response.contact) {
                    state.properties.grv.manual.contact[key] = response.contact[key]
                }

                this.dispatch('module1/getResult')

                return response
            } catch (e) {
                console.log(e)
            };
        },
        async getGRV({ state }) {
            const { data, pending, error, refresh } = await useFetch(`/api/grv_get/${state.options.caseId}/${state.properties.grv.variant}`)

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                return
            }
            
            state.properties.grv.manual.value = data.value.volume;
            state.properties.grv.manual.charts = data.value.charts;

            for (let key in data.value.thickness) {
                state.properties.grv.manual.thickness[key] = data.value.thickness[key]
            }
            for (let key in data.value.contact) {
                state.properties.grv.manual.contact[key] = data.value.contact[key]
            }

            return data.value
        },
        async getDistribution({ state }, payload) {

            state.options.propDataLoading = true;
            payload.key = payload.key.split('.')[payload.key.split('.').length - 1]

            let requestData = {
                distribution: payload.distribution,
                prop: payload.key
            };

            if (payload.openModal !== false) {
                if (payload.key == 'grv' || payload.key == 'area' || payload.key == 'thickness' || payload.key == 'average_thickness' || payload.key == 'geometric_factor' || payload.key == 'contact') {
                    state.properties.grv[state.properties.grv.variant][payload.key].params ? requestData.params = state.properties.grv[state.properties.grv.variant][payload.key].params : false;
                    state.properties.grv[state.properties.grv.variant][payload.key].crop ? requestData.crop = state.properties.grv[state.properties.grv.variant][payload.key].crop : false;
                } else {
                    state.properties[payload.key].params ? requestData.params = state.properties[payload.key].params : false;
                    state.properties[payload.key].crop ? requestData.crop = state.properties[payload.key].crop : false;
                }
            } else {
                if (payload.key == 'grv' || payload.key == 'area' || payload.key == 'thickness' || payload.key == 'average_thickness' || payload.key == 'geometric_factor' || payload.key == 'contact') {
                    state.properties.grv[state.properties.grv.variant][payload.key].params = {};
                    state.properties.grv[state.properties.grv.variant][payload.key].crop = {};
                } else {
                    state.properties[payload.key].params = {};
                    state.properties[payload.key].crop = {};
                }
            }
            
            const { data, pending, error, refresh } = await useFetch(`/api/get_distribution/${state.options.caseId}/`, {
                method: 'POST',
                body: requestData
            })

            if (error.value) {
                const errorMessage = error.value.data

                this.commit('common/setErrorData', {
                    statusCode: error.value.statusCode,
                    message: errorMessage.message,
                    showError: true
                })

                state.options.propDataLoading = false
                return
            }

            // Обновляем окно настроек

            payload.title ? state.propSettings.title = payload.title : false;
            payload.measurement ? state.propSettings.measurement = payload.measurement : false;
            state.propSettings.code = payload.key;
            state.propSettings.distribution = payload.distribution;
            state.propSettings.multiple_values = data.value.multiple_values;
            state.propSettings.crop = data.value.data.crop;
            state.propSettings.params = data.value.data.params;
            state.propSettings.required_parameters = [];

            data.value.required_parameters.forEach(param => {
                state.propSettings.required_parameters.push({code: param.code, title: param.name, value: data.value.data.params[param.code]})
            });

            state.propSettings.chart = data.value.charts.frequency;

            // Обновляем свойство

            if (payload.key == 'grv' || payload.key == 'area' || payload.key == 'thickness' || payload.key == 'average_thickness' || payload.key == 'geometric_factor' || payload.key == 'contact') {
                if (state.properties.grv.variant == 'range') {
                    state.properties.grv.range[payload.key].distribution = payload.distribution;
                    state.properties.grv.range[payload.key].multiple_values = data.value.multiple_values;
                    state.properties.grv.range[payload.key].crop = data.value.data.crop;
                    state.properties.grv.range[payload.key].params = data.value.data.params;
                } else if (state.properties.grv.variant == 'area_thickness') {
                    state.properties.grv.area_thickness[payload.key].distribution = payload.distribution;
                    state.properties.grv.area_thickness[payload.key].multiple_values = data.value.multiple_values;
                    state.properties.grv.area_thickness[payload.key].crop = data.value.data.crop;
                    state.properties.grv.area_thickness[payload.key].params = data.value.data.params;
                } else if (state.properties.grv.variant == 'manual') {
                    state.properties.grv.manual[payload.key].distribution = payload.distribution;
                    state.properties.grv.manual[payload.key].multiple_values = data.value.multiple_values;
                    state.properties.grv.manual[payload.key].crop = data.value.data.crop;
                    state.properties.grv.manual[payload.key].params = data.value.data.params;
                }
            } else {
                state.properties[payload.key].distribution = payload.distribution;
                state.properties[payload.key].multiple_values = data.value.multiple_values;
                state.properties[payload.key].crop = data.value.data.crop;
                state.properties[payload.key].params = data.value.data.params;
            }

            payload.openModal !== false ? state.options.openPropSettings = true : false
            state.options.propDataLoading = false;

            let updateKey = (payload.key == 'grv' || payload.key == 'area' || payload.key == 'average_thickness' || payload.key == 'thickness' || payload.key == 'geometric_factor' || payload.key == 'contact') ? 'grv' :  payload.key;

            await this.dispatch('module1/updatePropForm', updateKey).then(response => {
                if (!response) return;

                if ((payload.key == 'thickness' || payload.key == 'average_thickness' || payload.key == 'contact') && state.properties.grv.variant == 'manual') {
                    this.dispatch('module1/getGRV');
                }
            });
        },
    }
}